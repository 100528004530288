import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return ( 
    <footer>
      <div id="footer-top">
        <NavLink
          to="/"
          className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
        >
          <li>Home</li>
        </NavLink>
        <NavLink
          to="/products"
          className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
        >
          <li>Products</li>
        </NavLink>
        <NavLink
          to="/faq"
          className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
        >
          <li>FAQ</li>
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
        >
          <li>Contact</li>
        </NavLink>
      </div>
      <div id="footer-middle">
        <h2 className="footer-title">Contact us</h2>
        <ul className="contact">
          <li>
            <FontAwesomeIcon icon={faLocationDot} className="fa fa-location" />
            <p>
                            Bike Mitts <br />
                            3096 E 26th Avenue <br />
                            V5R 1L6, Vancouver, BC <br />
                            Canada
            </p>
          </li>
          <div className="contact-divider"></div>
          <li>
            <FontAwesomeIcon icon={faPhone} className="fa fa-phone" />
            <a href="tel:+12367774748">+1 236 777 4748</a>
          </li>
          <div className="contact-divider"></div>
          <li>
            <FontAwesomeIcon icon={faEnvelope} className="fa fa-envelope" />
            <a href="mailto:bikemitts.ca@gmail.com">bikemitts.ca@gmail.com</a>
          </li>
        </ul>
      </div>
      <div id="footer-bottom">
        <p>
                Copyright © Bikemitts.ca | All Rights Reserved. | <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          <br />
                Website by <a href="https://www.camilleherpin.com" target="_blank" rel="noreferrer">Camille Herpin</a>
        </p>
      </div>
    </footer>
  );
}
 
export default Footer;