import React from "react";
import { ProductsList } from "../../assets/productsList"
import Carousel from "../../components/Carousel";

const Products = () => {
  const productsList = ProductsList;
  return ( 
    <section id="products">
      <h2>Our Bike Mitts Collection</h2>
      {productsList.map((product, index) => {
        let images =[];
        if (product.picture2) {
          images = [product.picture1, product.picture2];
        }
        else {
          images = [product.picture1];
        }
        return (
          <article className="product" key={index}>
            <div className="product-image">
              <Carousel images={images} altText={product.title}/>
            </div>
            <div className="product-text">
              <h3>{product.title}</h3>
              <p>{product.description}</p>
            </div>
          </article>
        )
      })}
    </section>
  );
}
 
export default Products;