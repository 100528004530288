import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logo.png";

const Header = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  return ( 
    <header>
      <div className="background-image"></div>
      <div className="content">
        <div id="content-top">
          <div id="logo">
            <img src={logo} alt="logo Bike Mitts" />
            <h1>Bike Mitts</h1>
          </div>
          <nav className={isNavExpanded ? "nav expanded" : "nav"}>
            <button className="menu" onClick={() => {setIsNavExpanded(!isNavExpanded)}} aria-label="Menu Button">
              <FontAwesomeIcon icon={faBars} className="fa fa-menu" />
            </button>
            <ul className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
              <NavLink
                to="/"
                className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                onClick={() => {setIsNavExpanded(false)}}
              >
                <li>Home</li>
              </NavLink>
              <NavLink
                to="/products"
                className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                onClick={() => {setIsNavExpanded(false)}}
              >
                <li>Products</li>
              </NavLink>
              <NavLink
                to="/faq"
                className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                onClick={() => {setIsNavExpanded(false)}}
              >
                <li>FAQ</li>
              </NavLink>
              <NavLink
                to="/contact"
                className={({ isActive }) => isActive ? "active-link" : "inactive-link"}
                onClick={() => {setIsNavExpanded(false)}}
              >
                <li>Contact</li>
              </NavLink>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
 
export default Header;