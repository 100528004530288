export const faqContent = 
[
  {
    "id": 1,
    "question": "Are Bike Mitts water resistant?",
    "answer":"All bike mitts are made to be water resistant. The neoprene made ones are made from the same materials as divers suits, which means water glides right off them. The Faux leather ones are water resistant too, just to a more limited extent",
    "open": false
  },
  {
    "id": 2,
    "question": "Will they keep my hands warm?",
    "answer":"Bike mitts are designed to keep your hands protected from the elements, especially the wind, rain and snow, meaning they'll keep your hands toasty in the worst weather. They will protect your hands from the wind keeping the chill out and will help keep your hands dry too.",
    "open": false
  },
  {
    "id": 3,
    "question": "What material are Bike Mitts made from?",
    "answer":"Bike Mitts are made from either Neoprene or Faux Leather. Neoprene is the same material used in divers suits to go under water, meaning that the material is the perfect type to help glide water right off them. They are up to 5mm thick giving an extra thick protection from the elements. The faux leather is exactly that, faux leather. These are made more for people who want some protection from the elements, like wind and a little rain, but also want to keep an aesthetic when they ride.",
    "open": false
  },
  {
    "id": 4,
    "question": "Where are they made?",
    "answer":"Bike Mitts are made in Vancouver, BC. We're a local company that is driven to stay local. All materials are sourced from local businesses and we work directly with other local companies to have our Bike Mitts stay a local brand.",
    "open": false
  },
  {
    "id": 5,
    "question": "Who are you partnered with?",
    "answer":"We are currently partnered with Shift Delivery co-op. A Vancouver based delivery company that exclusively uses e-bikes to deliver. They have been using our Bike Mitts for over a year now and are extremely happy with how they are working out. ",
    "open": false
  },
  {
    "id": 6,
    "question": "What is your return policy?",
    "answer":"We're all about making sure you're satisfied with our Bike Mitts. So we offer a case by case basis for refunds and returns. Simply get in contact through out Contact Us page and we'll be more than happy to help!",
    "open": false
  },
]