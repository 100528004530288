import React, { useState } from "react";
import { Link } from "react-router-dom";
import { faqContent } from "../../assets/faqContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faCircleUp } from "@fortawesome/free-solid-svg-icons";


const FAQ = () => {
  const [faqs, setFaqs] = useState(faqContent);

  const toggleFAQ = index => {
    setFaqs(faqs.map((entry, i) => {
      if (i === index){
        entry.open = !entry.open;
      } else {
        entry.open = false;
      }
      return entry;
    }))
  }

  return ( 
    <section id="faq">
      {faqs.map((entry, index) => {
        return (
          <Link 
            className={"faq-item " + (entry.open ? "open" : "")}
            key={index}
            onClick={() => toggleFAQ(index)}
          >
            <div className='faq-question'>
              <h2>{entry.question}</h2>
                                
              {entry.open ? 
                <FontAwesomeIcon icon={faCircleUp} /> 
                : 
                <FontAwesomeIcon icon={faCircleDown} />
              }
            </div>
            <p className="faq-answer">{entry.answer}</p>
          </Link>
        )
      })
      }
    </section>
  );
}
 
export default FAQ;