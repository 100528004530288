import React from "react";
const PrivacyPolicy = () => {
  return ( 
    <div id="privacy-policy">
      <div className="privacy-policy-main-title">BIKE MITTS PRIVACY POLICY</div><br />
      <br />
        We are committed to protecting your privacy while allowing you to enjoy safe online access to our website. This Statement of Privacy applies to the Bike Mitts website only and governs data collection and usage. Your use of our website indicates your consent to the data practices described in this statement.<br />

      <br />
      <div className="privacy-policy-title">Collection of Personal Information</div>
        As a direct result of your use or contact through our website, Bike Mitts collects personally identifiable information from you that may include your e-mail address, name, home or work address or telephone number. We may also collect anonymous demographic information that is not unique to you, including your postal code, age, gender, preferences, interests and favorites. Information about your computer hardware and software is also automatically collected by Bike Mitts. This information can include your IP address, browser type, domain names, access times and referring website addresses. This information is necessary for Bike Mitts to maintain the operation and quality of the website, and to provide general statistics regarding use of our website.<br />
      <br />
      <div className="privacy-policy-title">Use of your Personal Information</div>
        Bike Mitts collects and uses your personal information for the sole purpose of operating our website and delivering the services you have requested. We may also use your personally identifiable information to inform you of other products or services available from Bike Mitts, although we do not sell, rent or lease our customer lists to third parties. Bike Mitts will disclose your personal information, without notice, only if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the law or comply with legal process served on Bike Mitts; (b) protect and defend the rights or property of Bike Mitts; and (c) act under exigent circumstances to protect the personal safety of users of Bike Mitts, or the public.<br />

      <br />
      <div className="privacy-policy-title">Security of your Personal Information</div>
        Bike Mitts secures the personally identifiable information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use or disclosure.<br />
      <br />
      <div className="privacy-policy-title">Changes to this Statement</div>
        Bike Mitts will occasionally update this Statement of Privacy to reflect company and customer feedback and changes to our website. We encourage you to periodically review this Statement to be informed of how Bike Mitts is protecting your information.

    </div> );
}
 
export default PrivacyPolicy;