import React from "react";
import BikeMittsHandleBars from "../../assets/BM5.png";
import BikeMittsLeather1 from "../../assets/BM1.png";
import BikeMittsLeather from "../../assets/BM2.png";
import BikeMittsNeoprene1 from "../../assets/BM3.png";
import BikeMittsNeoprene from "../../assets/BM4.png";
import Logo from "../../assets/logo.png";
import Divider from "../../components/Divider";
import { Link } from "react-router-dom";
import Carousel from "../../components/Carousel";

const Home = () => {
  const images = [BikeMittsNeoprene1, BikeMittsNeoprene, BikeMittsLeather1, BikeMittsLeather, BikeMittsHandleBars]
  return ( 
    <div id="home">
      <section id="presentation">
        <div className="text">
          <h2>Bike Mitts, handmade in Canada </h2>
          <article>
            <p>
              Based in beautiful British Columbia, we&apos;ve seen the blazing sunshine and wild snowstorms this land has to offer. 
              Being caught up in the rain and wind while cycling can be a tough gig so we&apos;ve managed to craft a durable, strong and warm mitt that fits right over your bike&apos;s handlebars. These mitts are designed to keep your hands warm and protected while riding. <br />
              <br />
              Designed with quality materials, these mitts are made to be warm and strong, helping protect your hands from the rain, cold, snow and wind!
            </p>
          </article>
        </div>
        <div className="image">
          <img src={BikeMittsHandleBars} alt="Bike Mitts on handlebars" />
          {/* <Link to="/products">
            <div className="products-popup"><h3>See our products</h3></div>
          </Link> */}
        </div>
      </section>
      <section id="our-products">
        <div className="image">
          <Carousel
            images = {images} 
            altText="Handlebar Mittens"
          />
          <Link to="/products">
            <div className="products-popup"><h3>See our products</h3></div>
          </Link>
        </div>
        <div className="text">  
          <h2>Handlebar Mitts for Extreme Protection</h2>
          <img src={Logo} alt="Bike Mitts Logo" />
        </div>     
      </section>
      <Divider />
      <section id="toasty">
        <h2>Designed to keep you toasty while riding</h2>
      </section>
      <Divider />
      <section id="cycle-with-style">
        <div className="text">
          <h2>Cycle with style </h2>
          <article>
            Bike mitts are designed to keep your hands protected from the rain, the wind, the snow and the cold. We also make sure they&apos;re as stylish as they can be. <br />
            They offer protection from extreme weather conditions.<br />
            <br />
            Simply undo the velcro, wrap around your handle bars and strap the velcro together.
          </article>
        </div>
        <div className="image">
          <img src={BikeMittsLeather} alt="Bike Mitts made with Faux Leather" />
            
          <Link to="/products">
            <div className="view-collection"><h3>View Collection</h3></div>
          </Link>
        </div>
      </section>

    </div>
  );
}
 
export default Home;