import BikeMittsHandleBars from "./BM5.png";
import BikeMittsLeather1 from "./BM1.png";
import BikeMittsLeather2 from "./BM2.png";
import BikeMittsNeoprene1 from "./BM3.png";
import BikeMittsNeoprene2 from "./BM4.png";

export const ProductsList = 
[
  {
    "title": "Black Neoprene Bike Mitts",
    "picture1": BikeMittsHandleBars,
    "description":"Made with quality thick neoprene these handlebar mittens are perfect to keep your hands warm and protected from all the elements while riding outdoors!",
  },
  {
    "title": "Faux Leather Bike Mitts",
    "picture1": BikeMittsLeather1,
    "picture2": BikeMittsLeather2,
    "description": "Made for the stylish cyclist. These handlebar mittens are made with quality faux leather which is ideal to keep your hands warm and protected while riding. Fit for commuter handle bars with easy velcro attachment, these are ideal for all cyclists, especially those that want to stay stylish during winter! Water Resistant",
  },
  {
    "title": "Blue Neoprene Bike Mitts",
    "picture1": BikeMittsNeoprene1,
    "picture2": BikeMittsNeoprene2,
    "description": "Made for extreme weather conditions. These handlebar mittens are made with quality neoprene which is ideal to keep your hands warm and protected while riding. Fit for commuter handle bars with easy velcro attachment, these are ideal for all cyclists!",
  },
]