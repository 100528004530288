import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./utils/style/index.scss";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer"
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Products from "./pages/Products";
import ScrollToTop from "./utils/helpers/ScrollToTop"

function App() {
  return (
    <BrowserRouter>
      <Header />
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
